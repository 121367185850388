import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccountDetailsComponent } from './account-details/account-details.component';
import { AccountFoldersEditComponent } from './account-folders-edit/account-folders-edit.component';
import { AccountFoldersComponent } from './account-folders/account-folders.component';
import { AccountManagersEditComponent } from './account-managers-edit/account-managers-edit.component';
import { AccountManagersViewComponent } from './account-managers-view/account-managers-view.component';
import { AccountManagersComponent } from './account-managers/account-managers.component';
import { AccountTabsComponent } from './account-tabs/account-tabs.component';
import { UserDetailsComponent } from '../user/user-details/user-details.component';
import { AccountUserFoldersComponent } from './account-user-folders/account-user-folders.component';
import { AccountConfigurationComponent } from './account-configuration/account-configuration.component';
import { AuthGuard } from '../guards/auth.guard';
import { AccountPandemicComponent } from './account-pandemic/account-pandemic.component';
import {AccountTagsComponent} from "./account-tags/account-tags.component";

const routes: Routes = [
  {
    path: 'account',
    component: AccountTabsComponent,
    canActivate: [
      AuthGuard
    ],
    children: [
      { path: 'configuration', component: AccountConfigurationComponent },
      { path: 'pandemic', component: AccountPandemicComponent },
      { path: 'details', component: AccountDetailsComponent },
      { path: 'folders', component: AccountFoldersComponent },
      { path: 'folders/:id/edit', component: AccountFoldersEditComponent },
      {
        path: 'user',
        children: [
          { path: 'details', component: UserDetailsComponent },
          { path: 'folders', component: AccountUserFoldersComponent }
        ]
      },
      { path: 'managers', component: AccountManagersComponent },
      {
        path: 'managers/:manager_id/edit',
        component: AccountManagersEditComponent
      },
      {
        path: 'managers/:manager_id/view',
        component: AccountManagersViewComponent
      },
      { path: 'tags', component: AccountTagsComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AccountRoutingModule {}
