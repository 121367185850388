<mat-sidenav-container>
  <mat-sidenav position="end" [opened]="true" [disableClose]="true" mode="side" *ngIf="site.id">

    <mat-card>
      <mat-card-content>
        <ng-container *ngIf="site.parent_site">
          <h4 class="text-white">{{ utils.getLangTerm('sites.singular', 'Site') }} #{{ site.parent_site.id }}</h4>
          <p>
            <b>Name</b>: {{ site.parent_site.name }} <br>
            <b>Address</b>: {{ site.parent_site.location }} <br>
            <b>Created At:</b> {{ (site.parent_site.date_created * 1000) | timezoneConvertMedium }}
          </p>
        </ng-container>

        <h4 class="text-white">{{ site.parent_site ? utils.getLangTerm('child-sites.singular', 'Subsite') : utils.getLangTerm('sites.singular', 'Site') }} #{{ site.id }}</h4>
        <p>
          <b>Name</b>: {{ site.name }} <br>
          <b>Address</b>: {{ site.location }} <br>
          <b>Created At:</b> {{ (site.date_created * 1000) | timezoneConvertMedium }}
        </p>
      </mat-card-content>

      <mat-card-actions>
        <button mat-button (click)="composeNewMessage(site.id)" matTooltip="Click this button to send a message to users associated with this {{ site.parent_site ? utils.getLangTerm('child-sites.singular', 'Subsite') : utils.getLangTerm('sites.singular', 'Site') }}.">
          <mat-icon>send</mat-icon>
          Send Message
        </button>
      </mat-card-actions>
    </mat-card>

    <mat-list>
      <mat-list-item routerLink="details" routerLinkActive="active-submenu-item"> Details </mat-list-item>
      <mat-list-item routerLink="induction" routerLinkActive="active-submenu-item"> Noticeboard </mat-list-item>
      <mat-list-item routerLink="contractors" routerLinkActive="active-submenu-item"> {{ utils.getLangTerm('contractors.plural', 'Contractors') }} (Sub-Safety Plans) </mat-list-item>
      <mat-list-item routerLink="managers" routerLinkActive="active-submenu-item"> Managers </mat-list-item>
      <mat-list-item routerLink="users-worked-on" routerLinkActive="active-submenu-item"> Users Worked On </mat-list-item>
      <mat-list-item routerLink="sssp-config" routerLinkActive="active-submenu-item"> Main Safety Plan </mat-list-item>
      <mat-list-item routerLink="dynamic-forms" routerLinkActive="active-submenu-item"> Forms </mat-list-item>
      <mat-list-item routerLink="codes" routerLinkActive="active-submenu-item"> QR Codes </mat-list-item>
      <mat-list-item routerLink="folders" routerLinkActive="active-submenu-item"> Documents </mat-list-item>
      <mat-list-item routerLink="hazards" routerLinkActive="active-submenu-item"> Hazards & Risks </mat-list-item>
      <mat-list-item routerLink="hazardous-substances" routerLinkActive="active-submenu-item"> Hazardous Substances </mat-list-item>
      <mat-list-item routerLink="safety-observations" routerLinkActive="active-submenu-item"> Safety Observations </mat-list-item>
      <mat-list-item routerLink="incidents" routerLinkActive="active-submenu-item"> Incidents </mat-list-item>
      <mat-list-item routerLink="toolbox-talks" routerLinkActive="active-submenu-item"> {{ utils.getLangTerm('toolbox-talks.plural', 'Toolbox Talks') }} </mat-list-item>
      <mat-list-item routerLink="audits" routerLinkActive="active-submenu-item"> Inspections & Audits </mat-list-item>
      <mat-list-item routerLink="task-analyses" routerLinkActive="active-submenu-item"> Task Analysis Documents </mat-list-item>
      <mat-list-item routerLink="task-analyses-revised" routerLinkActive="active-submenu-item"> TA/JSA/SWMS </mat-list-item>
      <mat-list-item routerLink="emergency-evacuation-plans" routerLinkActive="active-submenu-item"> Emergency Evacuation Plans </mat-list-item>
    </mat-list>
  </mat-sidenav>

  <section>
    <router-outlet></router-outlet>
  </section>
</mat-sidenav-container>

<ng-template #showLoader>
  <div class="d-flex justify-content-center align-items-center full-height">
    <table>
      <tr>
        <td class="d-flex justify-content-center">
          <mat-progress-spinner [mode]="'indeterminate'" diameter="32"></mat-progress-spinner>
        </td>
      </tr>
      <tr>
        <td class="d-flex justify-content-center">
          <p>Please wait while we get the information...</p>
        </td>
      </tr>
    </table>
  </div>
</ng-template>
