import { Auditable } from "../shared/auditable.model";
import { AccountModel } from "./account.model";
import {FolderModel} from "../shared/folder.model";

export class UserModel extends Auditable {

  id = 0;
  contact_person = '';
  username: string = '';
  email = '';
  password = '';
  password_confirmation: string = '';
  mobile = '';

  /**
   * Mobile Country Code fields.
   */
  mobile_e164: string = '';
  mobile_country_code: string = '';
  mobile_dial_code: string = '';

  /**
   * Redundant field used by Codeigniter.
   */
  auth_token = '';

  date_auth_token_expiry = 0;
  device_id = '';
  push_token = '';
  industry = 'Other Services';
  subscription = 'Free';

  // Used by the registration form as the name of the company to register as.
  entity_name = '';

  address = '';
  suburb = '';
  city = '';
  postal_code = '';
  country = '';
  profile_pic = '';
  is_sitesoft_admin = false;
  trade = '';
  current_password = '';
  name: string = '';

  iqp_number = '';

  company_name = '';

  /**
   * Unix timestamp when the account_id was last updated.
   */
  account_id_updated_at = 0;

  /**
   * Default account for this user.
   */
  account_id = 0;

  role: string = '';

  accounts: AccountModel[] = [];

  employer: AccountModel = new AccountModel();
  employer_name: string = '';

  pivot: any = {};

  /**
   * Unique hash used for unauthenticated access.
   */
  hash: string = '';

  folders: FolderModel[] = [];

  // These fields are used by toolbox talks (assignees).
  witness_by: number = 0;
  witness_at: number = 0;
  signed_by: number = 0;
  signed_at: number = 0;
  signature_url: string = '';

  // Used by toolbox talks for selfies.
  selfie_url: string = '';

  apply(data: any) {
    if ( data ) {
      Object.keys(data).forEach((key) => {
        // do not store unwanted data
        if (key in this) {
          switch ( key ) {
            case 'accounts':
              data[key].forEach((account: AccountModel) => {
                this[key].push(account);
              });
              break;
            case 'employer':
              this.employer.apply(data[key]);
              break;
            default:
              this[key] = data[key];
          }
        }
      });
    }
    return this;
  }

  reset() {
    this.id = 0;
    this.contact_person = '';
    this.email = '';
    this.password = '';
    this.mobile = '';
    this.mobile_e164 = '';
    this.mobile_country_code = '';
    this.mobile_dial_code = '';
    this.auth_token = '';
    this.date_auth_token_expiry = 0;
    this.device_id = '';
    this.push_token = '';
    this.industry = 'Other Services';
    this.subscription = 'Free';
    this.entity_name = '';
    this.address = '';
    this.suburb = '';
    this.city = '';
    this.postal_code = '';
    this.country = '';
    this.profile_pic = '';
    this.is_sitesoft_admin = false;
    this.current_password = '';
    this.iqp_number = '';
    this.company_name = '';
    this.account_id_updated_at = 0;
    this.account_id = 0;
    this.username = '';
    this.accounts.length = 0;

    this.date_created = 0;
    this.date_modified = 0;

    this.witness_by = 0;
    this.witness_at = 0;
    this.signed_by = 0;
    this.signed_at = 0;
    this.signature_url = '';

    this.employer.reset();
  }
}
