import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DirectivesModule } from '../directives/directives.module';
import { PipesModule } from '../pipes/pipes.module';
import { AccountDetailsComponent } from './account-details/account-details.component';
import { AccountFoldersEditComponent } from './account-folders-edit/account-folders-edit.component';
import { AccountFoldersComponent } from './account-folders/account-folders.component';
import { AccountManagersEditComponent } from './account-managers-edit/account-managers-edit.component';
import { AccountManagersViewComponent } from './account-managers-view/account-managers-view.component';
import { AccountManagersComponent } from './account-managers/account-managers.component';
import { AccountRoutingModule } from './account-routing.module';
import { AccountTabsComponent } from './account-tabs/account-tabs.component';
import { AccountUserFoldersComponent } from './account-user-folders/account-user-folders.component';
import { FilesModule } from '../files/files.module';
import { AccountConfigurationComponent } from './account-configuration/account-configuration.component';
import { RelatedSlaveAccountsComponent } from '../related-slave-accounts/related-slave-accounts.component';
import { AccountPandemicComponent } from './account-pandemic/account-pandemic.component';
import { EditorModule } from '@tinymce/tinymce-angular';
import { SharedModule } from '../shared/shared.module';
import { AccountTagsComponent } from './account-tags/account-tags.component';
import { AccountTagsEditComponent } from './account-tags-edit/account-tags-edit.component';
import { AccountTagsViewComponent } from './account-tags-view/account-tags-view.component';
import { AccountTagsFilterComponent } from './account-tags-filter/account-tags-filter.component';
import { AccountFoldersTabsComponent } from './account-folders-tabs/account-folders-tabs.component';
import { AccountFoldersLinksComponent } from './account-folders-links/account-folders-links.component';
import { AccountFoldersLinksEditComponent } from './account-folders-links-edit/account-folders-links-edit.component';
import { AccountFoldersLinksViewComponent } from './account-folders-links-view/account-folders-links-view.component';
import {FoldersModule} from "../folders/folders.module";


@NgModule({
  declarations: [
    AccountDetailsComponent,
    AccountFoldersComponent,
    AccountFoldersEditComponent,
    AccountManagersComponent,
    AccountManagersEditComponent,
    AccountManagersViewComponent,
    AccountTabsComponent,
    AccountUserFoldersComponent,
    AccountConfigurationComponent,
    RelatedSlaveAccountsComponent,
    AccountPandemicComponent,
    AccountTagsComponent,
    AccountTagsEditComponent,
    AccountTagsViewComponent,
    AccountTagsFilterComponent,
    AccountFoldersTabsComponent,
    AccountFoldersLinksComponent,
    AccountFoldersLinksEditComponent,
    AccountFoldersLinksViewComponent
  ],
    imports: [
        CommonModule,
        FormsModule,
        AccountRoutingModule,
        DirectivesModule,
        PipesModule,
        MatSelectModule,
        MatCheckboxModule,
        MatPaginatorModule,
        MatTableModule,
        MatSortModule,
        MatMenuModule,
        MatIconModule,
        MatListModule,
        MatDatepickerModule,
        MatTabsModule,
        MatInputModule,
        MatButtonModule,
        MatDialogModule,
        MatBadgeModule,
        FilesModule,
        MatSlideToggleModule,
        MatTooltipModule,
        MatCardModule,
        EditorModule,
        SharedModule,
        FoldersModule
    ],
  entryComponents: [
    AccountTagsEditComponent,
    AccountTagsViewComponent,
    AccountTagsFilterComponent,
    AccountFoldersTabsComponent,
    AccountFoldersLinksEditComponent,
    AccountFoldersLinksViewComponent
  ]
})
export class AccountModule {}
