import { Auditable } from "../shared/auditable.model";

export class SystemNotificationOptionModel extends Auditable {
  id = 0;
  code = '';
  text = '';
  description = '';
  is_private = false;

  is_enabled = false;

  apply(data: any) {
    if ( data ) {
      Object.keys(data).forEach((key) => {
        if (key in this) {
          this[key] = data[key];
        }
      });
    }
    return this;
  }

  reset() {
    this.id = 0;
    this.code = '';
    this.text = '';
    this.description = '';
    this.is_private = false;

    this.is_enabled = false;
  }
}
