<div class="container-fluid">
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <span class="navbar-brand">Notifications</span>
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <span class="mr-auto"> </span>

      <button mat-raised-button (click)="onSave()" color="primary">
        Save Preferences
      </button>
    </div>
  </nav>

  <div class="row">
    <div class="col-4" *ngFor="let system_notification_option of system_notification_options; let i = index">
      <div appMargin>
        <mat-checkbox [(ngModel)]="system_notification_options[i].is_enabled">{{system_notification_option.text}}</mat-checkbox>
        <br />
        <small>{{ system_notification_option.description }}</small>
      </div>
    </div>
  </div>
</div>
