import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { tap } from 'rxjs/operators';
import { AppService } from '../../app.service';
import { ApiService } from '../../shared/api.service';
import { UtilsService } from '../../shared/utils.service';
import { CustomDataSource } from '../../utils/custom-data-source';
import {Router} from "@angular/router";

const RESOURCE_URL = 'account-managers/';

/**
 * This component is redundant and should be removed in a future release.
 */
@Component({
  selector: 'app-account-managers',
  templateUrl: './account-managers.component.html',
  styleUrls: ['./account-managers.component.css']
})
export class AccountManagersComponent implements OnInit, AfterViewInit {
  // columns to show and the data source
  displayedColumns: string[] = [
    'select',
    'device_id',
    'contact_person',
    'email',
    'mobile',
    'permissions',
    'actions'
  ];
  dataSource = new AccountManagersDataSource(this.app, this.api);

  // the paginator and sorter
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    // public service: AccountManagersService,
    public app: AppService,
    private api: ApiService,
    public utils: UtilsService,
    private router: Router
  ) {}

  ngOnInit() {
    // Redirect the user to the list of employees.
    this.router.navigate(['/employees']);
    return;

    // this.dataSource.getData();
  }

  ngAfterViewInit() {
    // reset the paginator when sorting
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    const _tap = tap(() => {
      this.dataSource.limit = this.paginator.pageSize;
      this.dataSource.offset = this.paginator.pageIndex;
      this.dataSource.sort_by = this.sort.active;
      this.dataSource.sort_order = this.sort.direction;

      this.dataSource.getData();
    });

    // subscribe to the paginator tap events
    this.paginator.page.pipe(_tap).subscribe();
    this.sort.sortChange.pipe(_tap).subscribe();
  }

  onUpdatePermissions(event: any, link_id: number) {
    if (event.value === 'Employee') {
      this.utils.showModal(
        'Updating Account Manager',
        `Are you sure you want to change the permissions of the account administrator to "Employee"?
        The administrator will be removed from this list and added to your list of employees.`,
        () => {
          this.api.laravelApiRequest(
            'put',
            RESOURCE_URL + link_id,
            { permissions: 'Employee' },
            {},
            (response) => {
              this.utils.showToast(
                'The account manager\'s permissions was changed to employee level permissions.'
              );
              this.dataSource.getData();
            }
          );
        }
      );
    }
  }

  onRemove(id: number) {
    this.utils.showModal(
      'Remove Account Manager',
      `Are you sure you want to remove this account manager? If the account manager
      should be an employee instead, cancel this option and change their role to "Employee".`,
      () => {
        this.api.laravelApiRequest(
          'delete',
          RESOURCE_URL + id,
          {},
          {},
          (response) => {
            this.utils.showToast('The account manager was removed');
            this.dataSource.selection.deselect(id);
            this.dataSource.getData();
          }
        );
      }
    );
  }

  onDeleteSelection() {
    this.utils.showModal(
      'Remove Selected Account Managers',
      'Are you sure you want to remove the selected account managers?',
      () => {
        this.api.request(
          'delete',
          RESOURCE_URL + this.dataSource.selection.selected.join('/'),
          {},
          {},
          (response) => {
            this.utils.showToast('The selected account managers was removed');
            this.dataSource.selection.clear();
            this.dataSource.getData();
          }
        );
      }
    );
  }
}

/**
 * the data source class should contain everything needed to get data for the feature.
 * this only applies to lists. forms will use direct calls to the api.
 */
export class AccountManagersDataSource extends CustomDataSource {
  // record sorting and direction
  sort_by = 'contact_person';
  sort_order = 'asc';

  getData(resetOffset: boolean = false) {
    this.getDataFromLaravelAPI('account-managers', resetOffset);
  }
}
