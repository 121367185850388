<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <span class=" mr-auto">
    <button mat-raised-button routerLink="/account/managers" appMarginRight>
      <mat-icon>arrow_back</mat-icon> Account Managers
    </button>

    <button mat-raised-button
      routerLink="/account/managers/{{ manager.id }}/edit"
      *ngIf="manager.id"
      appMarginRight>
      Edit
    </button>

    <button mat-raised-button
      (click)="onRemove()"
      color="warn"
      *ngIf="manager.id"
      appMarginRight>
      Delete
    </button>
  </span>
</nav>

<div class="container-fluid" appPadding>
  <div class="row">
    <div class="col-12 col-md-6">
      <ng-container *ngIf="manager.email">
        <h6>Email</h6>
        <p>{{ manager.email }}</p>
      </ng-container>

      <ng-container *ngIf="manager.mobile">
        <h6>Mobile</h6>
        <p>{{ manager.mobile }}</p>
      </ng-container>

      <ng-container *ngIf="manager.contact_person">
        <h6>Full Name</h6>
        <p>{{ manager.contact_person }}</p>
      </ng-container>

      <ng-container *ngIf="manager.entity_name">
        <h6>Entity Name</h6>
        <p>{{ manager.entity_name }}</p>
      </ng-container>

      <ng-container *ngIf="manager.permissions">
        <h6>Permissions</h6>
        <p>{{ manager.permissions }}</p>
      </ng-container>
    </div>

    <div class="col-12 col-md-6"></div>
  </div>
</div>
