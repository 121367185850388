import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from '../../app.service';
import { AccountManagerModel } from '../../models/account-manager.model';
import { ApiService } from '../../shared/api.service';
import { UtilsService } from '../../shared/utils.service';

/**
 * This component is redundant and should be removed in a future release.
 */
@Component({
  selector: 'app-account-managers-edit',
  templateUrl: './account-managers-edit.component.html',
  styleUrls: ['./account-managers-edit.component.css']
})
export class AccountManagersEditComponent implements OnInit {
  // id: number;

  manager: AccountManagerModel = new AccountManagerModel();

  inProgress = false;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public app: AppService,
    private api: ApiService,
    public utils: UtilsService
  ) {}

  ngOnInit() {
    // Redirect the user to the employees list.
    this.router.navigate(['/employees']);
    return;
  }

  onSubmit(form: NgForm) {
    if (!form.valid) {
      this.utils.showModal(
        'Error',
        'Please make sure you enter all fields with valid information.'
      );
      return;
    }

    if (this.inProgress) {
      return;
    }

    this.inProgress = true;

    this.api.laravelApiRequest(
      'post',
      'account-managers',
      this.manager,
      {},
      (response) => {
        this.utils.showToast('The account manager was added.');
        // and navigate back to the list of sites
        this.router.navigate(['/account', 'managers']);
        this.inProgress = false;
      },
      (_error: any) => this.inProgress = false
    );
  }
}
