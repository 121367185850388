import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { SystemNotificationOptionModel } from 'src/app/models/system-notification-option.model';
import { ApiService } from 'src/app/shared/api.service';
import { UtilsService } from 'src/app/shared/utils.service';

@Component({
  selector: 'app-employees-notifications',
  templateUrl: './employees-notifications.component.html',
  styleUrls: ['./employees-notifications.component.scss']
})
export class EmployeesNotificationsComponent implements OnInit {
  employee_id: number;

  system_notification_options: SystemNotificationOptionModel[] = [];

  constructor(
    public app: AppService,
    private api: ApiService,
    public utils: UtilsService,
    public route: ActivatedRoute
  ) {}

  ngOnInit() {
    // get the selected user id
    this.employee_id = Number(this.route.parent.snapshot.params['user_id']);

    // get system notification options
    this.api.laravelApiRequest(
      'get',
      'system-notification-options',
      {},
      {},
      (response) => {
        this.system_notification_options.length = 0;
        response.data.forEach((system_option) => {
          this.system_notification_options.push(
            new SystemNotificationOptionModel().apply(system_option)
          );
        });

        // get user notification options
        this.api.laravelApiRequest(
          'get',
          'employees/' + this.employee_id + '/notification-options',
          {},
          {},
          (response_1) => {
            response_1.data.forEach((option) => {
              const system_option_index = this.system_notification_options.findIndex(
                (options) =>
                  options.id === option.system_notification_option_id
              );

              if (system_option_index !== -1) {
                this.system_notification_options[
                  system_option_index
                ].is_enabled = option.is_enabled;
              }
            });
          }
        );
      }
    );
  }

  onSave() {
    this.api.laravelApiRequest(
      'put',
      'employees/' + this.employee_id + '/notification-options',
      {
        user_notification_options: this.system_notification_options
      },
      {},
      (response) => {
        this.utils.showToast(`${ this.utils.getLangTerm('employees.singular', 'Employee') } Preferences Updated.`);
      }
    );
  }
}
