<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <span class=" mr-auto">
    <button mat-raised-button routerLink="/account/managers" appMarginRight>
      <mat-icon>arrow_back</mat-icon> Account Managers
    </button>
  </span>
</nav>

<div class="container-fluid" appPadding>
  <div class="row">
    <div class="col-12 col-md-6">
      <form #f="ngForm" class="form-container" (ngSubmit)="onSubmit(f)">
        <div class="card">
          <div class="card-body form-container">
            <h5 class="card-title">Account Manager Form</h5>

            <mat-form-field>
              <input
                matInput
                placeholder="Full Name"
                [(ngModel)]="manager.contact_person"
                name="contact_person"
                type="text"
                #contact_person
                required/>
              <mat-error *ngIf="!contact_person.valid">
                Please enter the full name.
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <input
                matInput
                placeholder="Email Address"
                [(ngModel)]="manager.email"
                name="email"
                type="email"
                #email
                required
                email/>
              <mat-error *ngIf="!email.valid">
                Please enter a valid email address.
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <input matInput
                placeholder="Mobile"
                [(ngModel)]="manager.mobile"
                name="mobile"
                type="tel"
                #mobile/>
              <mat-error *ngIf="!mobile.valid">
                Please enter a valid mobile number.
              </mat-error>
            </mat-form-field>

            <button type="submit"
              mat-raised-button
              [disabled]="!f.valid || inProgress"
              color="primary"
              class="save-button">
              Save
            </button>
          </div>
        </div>
      </form>
    </div>

    <div class="col-12 col-md-6">
      <h4>Account Managers</h4>
      <p>
        Account managers have full account management privileges e.g. they can manage your {{ utils.getLangTerm('parent-child-sites-combined.plural', 'Sites').toLowerCase() }}, {{ utils.getLangTerm('contractors.plural', 'Contractors').toLowerCase() }}, {{ utils.getLangTerm('employees.plural', 'Employees') }} and all other features.
      </p>
    </div>
  </div>
</div>
