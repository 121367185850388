<mat-toolbar>
  <button mat-icon-button (click)="sidenav.toggle()" class="menu-toggler" *ngIf="routerService.isAuthRoute()" matTooltip="Toggle showing the side menu">
    <mat-icon>menu</mat-icon>
  </button>

  <button mat-button id="account-name" [matMenuTriggerFor]="switcherMenu" (click)="openAccountSwitcher()" matTooltip="Open the account switcher" *ngIf="app.isAuthenticated">
    {{ getAccountName() }}
    <mat-icon>arrow_drop_down</mat-icon>
  </button>

  <mat-menu #switcherMenu="matMenu">
    <input #searchInput matInput placeholder="Search" (click)="$event.stopPropagation()" (change)="onSearchAccounts()" (blur)="searchInput.focus()" [(ngModel)]="accountSearch" id="switcher-search" class="p-3 border-bottom" />
    <button mat-menu-item *ngFor="let account of filteredAccounts" (click)="switchAccount(account.id)" matTooltip="Switch to account {{account.name}}">
      {{ account.name }}
    </button>
  </mat-menu>

  <span style="flex: 1 1 auto;"></span>

  <ng-container *ngIf="!mobileQuery.matches && utils.getUpvotyLink()">
    <a [href]="utils.getUpvotyLink()" target="_blank" mat-button style="color: #fff !important;" matTooltip="Open the community-driven roadmap to report:&#13;- Feature Requests&#13;- Performance Issues&#13;">
      <mat-icon>timeline</mat-icon>
      Roadmap
    </a>
  </ng-container>

  <ng-container *ngIf="!mobileQuery.matches">
    <a [href]="utils.getKnowledgebaseUrl()" target="_blank" mat-button style="color: #fff !important;" matTooltip="Open the user guide to learn more.">
      <mat-icon>library_books</mat-icon>
      User Guide
    </a>
  </ng-container>

  <span appMarginLeft *ngIf="!mobileQuery.matches">{{ utils.getEnvModeAsText() }}</span>
  <span appMarginLeft>{{ utils.getAppVersion() }}</span>

  <button mat-icon-button [matMenuTriggerFor]="adminMenu" *ngIf="app.isAuthenticated && app.user.is_sitesoft_admin" matTooltip="System Administrators Menu">
    <mat-icon>apps</mat-icon>
  </button>

  <mat-menu #adminMenu="matMenu">
    <button mat-menu-item routerLink="/admin">
      <mat-icon>menu</mat-icon>
      <span>Admin</span>
    </button>

    <button mat-menu-item routerLink="/admin/accounts">
      <mat-icon>account_tree</mat-icon>
      <span>Accounts</span>
    </button>

    <button mat-menu-item routerLink="/admin/sites">
      <mat-icon>poll</mat-icon>
      <span>All {{ utils.getLangTerm('parent-child-sites-combined.plural', 'Sites') }}</span>
    </button>

    <button mat-menu-item routerLink="/admin/users">
      <mat-icon>people</mat-icon>
      <span>Users</span>
    </button>

    <button mat-menu-item routerLink="/admin/codes">
      <mat-icon>qr_code</mat-icon>
      <span>QR Codes</span>
    </button>

    <button mat-menu-item routerLink="/items">
      <mat-icon>assignment</mat-icon>
      <span>Pre-Qualification Items</span>
    </button>

    <button mat-menu-item routerLink="/gitlab-issues">
      <mat-icon>construction</mat-icon>
      <span>Development Schedule</span>
    </button>

    <button mat-menu-item routerLink="/admin/trades">
      <mat-icon>work</mat-icon>
      <span>Job Titles</span>
    </button>

    <button mat-menu-item routerLink="/admin/industries">
      <mat-icon> business</mat-icon>
      <span>Industries</span>
    </button>

    <button mat-menu-item routerLink="/admin/ppe">
      <mat-icon>headset</mat-icon>
      <span>PPE</span>
    </button>

    <button mat-menu-item routerLink="/admin/work-permits">
      <mat-icon>assignment_ind</mat-icon>
      <span>Work Permits</span>
    </button>

    <button mat-menu-item routerLink="/admin/task-analyses-revised">
      <mat-icon>developer_board</mat-icon>
      <span>TA/JSA/SWMS Templates</span>
    </button>
  </mat-menu>

  <button mat-icon-button [matMenuTriggerFor]="helpMenu" matTooltip="Help">
    <mat-icon>help</mat-icon>
  </button>

  <mat-menu #helpMenu="matMenu">
    <ng-container *ngIf="mobileQuery.matches && utils.getUpvotyLink()">
      <a [href]="utils.getUpvotyLink()" target="_blank" style="text-decoration: none; color: inherit;" mat-menu-item matTooltip="Open the community-driven roadmap to report:&#13;- Feature Requests&#13;- Performance Issues&#13;">
        <mat-icon>timeline</mat-icon>
        Roadmap
      </a>
    </ng-container>

    <ng-container *ngIf="mobileQuery.matches">
      <a [href]="utils.getKnowledgebaseUrl()" target="_blank" style="text-decoration: none; color: inherit;" mat-menu-item matTooltip="Open the user guide to learn more.">
        <mat-icon>library_books</mat-icon>
        User Guide
      </a>
    </ng-container>

    <a [href]="utils.getReleaseNotesUrl()" target="_blank" style="text-decoration: none; color: inherit;" mat-menu-item matTooltip="Open the release notes for the software.">
      <mat-icon>notes</mat-icon>
      Release Notes
    </a>

    <button mat-menu-item routerLink="/contact-us">
      <mat-icon>email</mat-icon>
      <span>Contact Us</span>
    </button>
  </mat-menu>

  <button mat-icon-button [matMenuTriggerFor]="settingsMenu" *ngIf="app.isAuthenticated && app.userAccountLink.permissions != 'Employee'" matTooltip="Configure your Company information">
    <mat-icon>settings</mat-icon>
  </button>

  <mat-menu #settingsMenu="matMenu">
    <button mat-menu-item routerLink="/account/details">
      <mat-icon>business</mat-icon>
      <span>Company Details</span>
    </button>

    <button mat-menu-item routerLink="/account/configuration">
      <mat-icon>build</mat-icon>
      <span>Configuration</span>
    </button>

    <button mat-menu-item routerLink="/account/pandemic">
      <mat-icon>add_alert</mat-icon>
      <span>Covid-19 Setup</span>
    </button>

    <button mat-menu-item routerLink="/account/folders">
      <mat-icon>folder</mat-icon>
      <span>Company Documents</span>
    </button>

    <button mat-menu-item routerLink="/employees">
      <mat-icon>person</mat-icon>
      <span>Account Managers</span>
    </button>
    <button mat-menu-item routerLink="/account/tags">
      <mat-icon> local_offer</mat-icon>
      <span>Manage Tags</span>
    </button>
  </mat-menu>

  <button mat-icon-button [matMenuTriggerFor]="accountMenu" *ngIf="app.isAuthenticated" matTooltip="Configure your User information">
    <mat-icon [matBadge]="userMessagesService.unread_messages_count.toString()" matBadgePosition="above">account_circle</mat-icon>
  </button>

  <mat-menu #accountMenu="matMenu">
    <button mat-menu-item routerLink="/user/details">
      <mat-icon>account_box</mat-icon>
      <span>{{ app.user.contact_person }}</span>
    </button>

    <button mat-menu-item routerLink="/user/multi-account-dashboard">
      <mat-icon>dashboard</mat-icon>
      <span>Multi-Account Dashboard</span>
    </button>

    <button mat-menu-item routerLink="/user/messages">
      <mat-icon [matBadge]="userMessagesService.unread_messages_count.toString()" matBadgePosition="above">message</mat-icon>
      <span>My Messages</span>
    </button>

    <button mat-menu-item routerLink="/user/tasks">
      <mat-icon>list</mat-icon>
      <span>My Tasks</span>
    </button>

    <button mat-menu-item routerLink="/user/dynamic-forms">
      <mat-icon>assignment</mat-icon>
      <span>My Forms</span>
    </button>

    <button mat-menu-item routerLink="/account/user/folders">
      <mat-icon>assignment</mat-icon>
      <span>Training/Competency</span>
    </button>

    <button mat-menu-item routerLink="/login-credentials">
      <mat-icon>lock</mat-icon>
      <span>Change Login Credentials</span>
    </button>

    <button mat-menu-item (click)="onSignout()">
      <mat-icon>exit_to_app</mat-icon>
      <span>Log out</span>
    </button>
  </mat-menu>

</mat-toolbar>
