import { Auditable } from "../shared/auditable.model";

export class AccountManagerModel extends Auditable{

  entity_name: string;
  id = 0;
  contact_person = '';
  email = '';
  mobile = '';
  permissions = 'Administrator'; // Project Manager
  iqp_number = '';

  apply(data: any) {
    if ( data ) {
      Object.keys(data).forEach((key) => {
        if (key in this) {
          this[key] = data[key];
        }
      });
    }
    return this;
  }

  reset() {
    this.id = 0;
    this.contact_person = '';
    this.email = '';
    this.mobile = '';
    this.permissions = 'Administrator'; // Project Manager
    this.iqp_number = '';
  }
}
