import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from '../../app.service';
import { AccountManagerModel } from '../../models/account-manager.model';
import { ApiService } from '../../shared/api.service';
import { UtilsService } from '../../shared/utils.service';

/**
 * This component is redundant and should be removed in a future release.
 */
@Component({
  selector: 'app-account-managers-view',
  templateUrl: './account-managers-view.component.html',
  styleUrls: ['./account-managers-view.component.css']
})
export class AccountManagersViewComponent implements OnInit {
  // id: number;

  manager: AccountManagerModel = new AccountManagerModel();

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public app: AppService,
    private api: ApiService,
    public utils: UtilsService
  ) {}

  ngOnInit() {
    // Redirect the user to the employees list.
    this.router.navigate(['/employees']);
    return;

    // get the site id
    this.manager.id = Number(this.route.snapshot.params['manager_id']);

    // check if it's a valid id otherwise it's a new site being created.
    // NaN, NULL, 0, any sequence of letters will trigger a new site creation form.
    if (this.manager.id) {
      this.api.request(
        'get',
        'account-managers/' + this.manager.id,
        {},
        {},
        (response) => {
          this.manager.apply(response.data[0]);
        }
      );

      // load the site info
      // this.service.get(this.manager.id, true);
    } else {
      this.router.navigate(['account', 'managers']);
    }
  }

  onRemove() {
    this.utils.showModal(
      'Remove Account Manager',
      'Are you sure you want to remove this account manager?',
      () => {
        this.api.request(
          'delete',
          'account-managers/' + this.manager.id,
          {},
          {},
          (response) => {
            this.utils.showToast('The account manager was removed');
            this.router.navigate(['account', 'managers']);
          }
        );
      }
    );
  }
}
