<div class="container-fluid">
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <span class="mr-auto">
        <button mat-raised-button color="primary" routerLink="/account/managers/add/edit" appMarginRight>
          Add Account Manager
        </button>

        <mat-menu #appMenu="matMenu">
          <button mat-menu-item (click)="dataSource.deselectAll()">
            Deselect All
          </button>
          <button mat-menu-item (click)="onDeleteSelection()">
            Delete Selected
          </button>
        </mat-menu>

        <button mat-raised-button color="secondary" [matMenuTriggerFor]="appMenu"
          *ngIf="dataSource.selection.hasValue()">
          List Actions
        </button>
      </span>

      <span class="spinner-container" *ngIf="(dataSource.loading | async)" appMarginRight>

      </span>

      <form class="form-inline my-2 my-lg-0" (ngSubmit)="dataSource.getData(true)">
        <input class="form-control mr-sm-2" type="search" [(ngModel)]="dataSource.search" name="search"
          placeholder="Search" aria-label="Search" />
      </form>

      <button mat-raised-button color="primary" (click)="dataSource.getData()" appMarginRight>
        <mat-icon>filter_list</mat-icon> Filter
      </button>
      <!--button mat-raised-button (click)="export()" color="secondary"><mat-icon>import_export</mat-icon> Export</button-->
    </div>
  </nav>

  <div class="mat-elevation-z8 scroll-box">
    <table mat-table [dataSource]="dataSource" matSort matSortActive="contact_person" matSortDirection="asc"
      matSortDisableClear>
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox
            (change)="$event ? dataSource.selectAllToggle() : null"
            [checked]="dataSource.selection.hasValue() && dataSource.isAllSelected()"
            [indeterminate]="dataSource.selection.hasValue() && !dataSource.isAllSelected()">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()"
            (change)="$event ? dataSource.selection.toggle(row.id) : null"
            [checked]="dataSource.selection.isSelected(row.id)">
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="device_id">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <mat-icon matTooltip="This user is connected to the {{ utils.getAppName() }} mobile app{{ element.version ? (', using version ' + element.version) : ''}}." *ngIf="element.device_id">
            phonelink_setup
          </mat-icon>
        </td>
      </ng-container>

      <!-- Full Name Column -->
      <ng-container matColumnDef="contact_person">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Full Name
        </th>
        <td mat-cell *matCellDef="let element">{{ element.contact_person }}</td>
      </ng-container>

      <!-- Email Column -->
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Email Address</th>
        <td mat-cell *matCellDef="let element">{{ element.email }}</td>
      </ng-container>

      <!-- Mobile Column -->
      <ng-container matColumnDef="mobile">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Mobile Number</th>
        <td mat-cell *matCellDef="let element">{{ element.mobile }}</td>
      </ng-container>

      <!-- Role Column -->
      <ng-container matColumnDef="permissions">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Role</th>
        <td mat-cell *matCellDef="let element">
          <mat-form-field>
            <mat-select (selectionChange)="onUpdatePermissions($event, element.id)" [value]="element.permissions">
              <mat-option value="Administrator">Administrator</mat-option>
              <mat-option value="Employee">Employee</mat-option>
            </mat-select>
          </mat-form-field>
        </td>
      </ng-container>

      <!-- Actions Column -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <a href="javascript:void(0)" (click)="onRemove(element.id)" class="" matTooltip="Remove the account manager."
            matTooltipPosition="above">
            <!--mat-icon>delete</mat-icon-->Delete</a>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator [length]="dataSource?.total | async" [pageSize]="dataSource.limit"
      [pageSizeOptions]="dataSource.pageSizeOptions" showFirstLastButtons></mat-paginator>
  </div>
</div>
