<!--div class="row">
  <div class="col">
    <div class="alert alert-warning" role="alert">
      <b>Important Notice:</b> From September 2023 all {{ utils.getAppName() }} users will need to have a unique email in order to sign in.
      If you are currently using a shared email please update it in <a routerLink="/user/details">your profile</a> before September 2023.
      For more information visit the <a [href]="utils.getUrgentNoticeUrl()" target="_blank">{{ utils.getAppName() }} Help Centre</a>.
    </div>
  </div>
</div-->

<div class="col-12" *ngIf="app.userAccountLink.permissions == 'Employee'">
  <div class="alert alert-info" role="alert">
    Thank you for using {{ utils.getAppName() }}. You are viewing the {{ utils.getAppName() }} web portal (for administrative use only). Please close the web portal and open the {{ utils.getAppName() }} mobile app for {{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site').toLowerCase() }} access. Download and install <b><a [href]="utils.getAndroidMobileAppStoreUrl()" target="_blank">{{ utils.getAppName() }} for Android</a></b> or <b><a [href]="utils.getIosMobileAppStoreUrl()" target="_blank">{{ utils.getAppName() }} for iOS</a></b>.
  </div>
</div>

<div class="row">
  <div class="col">
    <button
      mat-raised-button
      color="secondary"
      (click)="onSelectSites()"
      [matBadge]="'' + (app.chart_selected_sites.length ? app.chart_selected_sites.length : 'All')"
      appMarginRight
      matTooltip="Filter all results by the selected {{ utils.getLangTerm('parent-child-sites-combined.plural', 'Sites').toLowerCase() }}"
      style="float: left;">
      <mat-icon>domain</mat-icon> Select {{ utils.getLangTerm('parent-child-sites-combined.plural', 'Sites') }}
    </button>

    <input
      type="text"
      readonly
      placeholder="Select Date Range"
      [(ngModel)]="app.filter_date_range"
      name="filter_date_range"
      class="form-control"
      bsDaterangepicker
      [bsConfig]="datepickerConfig"
      appMarginRight
      matTooltip="Filter all results within the selected date range" />

    <button mat-raised-button color="secondary" (click)="refreshStats()"
      matTooltip="Refresh the results" style="float: left;">
      <mat-icon>refresh</mat-icon> Refresh
    </button>
  </div>
</div>

<div class="row" appMarginTop>
  <div class="col">
    <mat-card>
      <mat-card-content>
        <h5>All {{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site') }} Activities</h5>
        <div>
          <canvas style="height: 250px !important;" id="site_activities_chart_ref">{{ site_activities_chart }}</canvas>
        </div>
      </mat-card-content>
      <mat-card-actions>
        <button
          mat-raised-button
          color="secondary"
          [routerLink]="['/', 'reports', 'access-records']"
          matTooltip="Go to the 'Sign In/Out Records' page">
          Sign In/Out Records
        </button>

        <button
          mat-raised-button
          color="secondary"
          [routerLink]="['/', 'reports', 'onsite-records']"
          matTooltip="Go to the 'Currently Onsite Records' page">
          Currently Onsite Records
        </button>

        <button
          mat-raised-button
          color="secondary"
          [routerLink]="['/', 'reports', 'time-onsite-records']"
          matTooltip="Go to the 'Time Spent Onsite' page">
          Time Spent Onsite Records
        </button>
      </mat-card-actions>
    </mat-card>

    <mat-card>
      <mat-card-content>
        <h5>Hazards & Risks</h5>
        <div>
          <canvas style="height: 250px !important;" id="hazards_chart_ref">{{ hazards_chart }}</canvas>
        </div>
      </mat-card-content>
      <mat-card-actions>
        <button
          mat-raised-button
          color="secondary"
          [routerLink]="['/', 'hazards']"
          matTooltip="Go to the 'Hazard & Risk Templates' page">
          Hazard & Risk Templates
        </button>
      </mat-card-actions>
    </mat-card>

    <mat-card>
      <mat-card-content>
        <h5>Your Connected Network</h5>
        <div>
          <canvas id="users_chart_ref">{{ users_chart }}</canvas>
        </div>
      </mat-card-content>
      <mat-card-actions>

        <button
          mat-raised-button
          color="secondary"
          [routerLink]="['/', 'contractors']"
          matTooltip="Go to the '{{ utils.getLangTerm('contractors.plural', 'Contractors') }}' page">
          {{ utils.getLangTerm('contractors.plural', 'Contractors') }}
        </button>

        <button
          mat-raised-button
          color="secondary"
          [routerLink]="['/', 'employees']"
          matTooltip="Go to the '{{ utils.getLangTerm('employees.plural', 'Employees') }}'">
          {{ utils.getLangTerm('employees.plural', 'Employees') }}
        </button>

      </mat-card-actions>
    </mat-card>
  </div>

  <div class="col">
    <mat-card>
      <mat-card-content>
        <h5>Release Summary</h5>
        <ul>
          <li><b>Tasks Feature Updates:</b> Users can now complete tasks that are assigned to them in the web portal.</li>
          <li><b>Tasks Feature Updates:</b> Contractor admin users can now see tasks created by their staff members. </li>
          <li><b>Forms Feature Updates:</b> Public user profiles now show forms that are assigned to the users.</li>
          <li><b>Kiosk Updates:</b> Auto-completion were added to allow repeating {{ utils.getLangTerm('parent-child-sites-combined.plural', 'Sites').toLowerCase() }} sign-ins to be completed quicker.</li>
          <li><b>QR Codes Updates:</b> Users can now sign out of {{ utils.getLangTerm('parent-child-sites-combined.plural', 'Sites').toLowerCase() }} by scanning the QR codes without the mobile app.</li>
          <li>Please visit the <a [href]="utils.getKnowledgebaseUrl()" target="_blank" matTooltip="Open the Help Centre to learn more.">Help Centre</a> for more information.</li>
        </ul>

        <div class="alert alert-info" role="alert">
          Thank you for using {{ utils.getAppName() }}. You are viewing the {{ utils.getAppName() }} web portal (for administrative use only). The {{ utils.getAppName() }} mobile app can be downloaded from the <b><a [href]="utils.getAndroidMobileAppStoreUrl()" target="_blank">Google Play Store for Android</a></b> or <b><a [href]="utils.getIosMobileAppStoreUrl()" target="_blank">App Store for iOS</a></b>.
        </div>
      </mat-card-content>
      <mat-card-actions></mat-card-actions>
    </mat-card>

    <mat-card>
      <mat-card-content>
        <h5>Incidents & Near Misses</h5>
        <div>
          <canvas style="height: 250px !important;" id="incidents_chart_ref">{{ incidents_chart }}</canvas>
        </div>
      </mat-card-content>
      <mat-card-actions>
        <button
          mat-raised-button
          color="secondary"
          [routerLink]="['/', 'incidents']"
          matTooltip="Go to the 'Incident & Near Miss Register' page">
          Incident & Near Miss Register
        </button>
      </mat-card-actions>
    </mat-card>
  </div>
</div>
